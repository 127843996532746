<template>
  <b-row>
    <b-col>
      <b-row
        align-h="between"
      >
        <b-col>
          <b-row>
            <b-col :cols="colunasBusca">
              <b-form-group
                style="margin-bottom:0.5rem"
              >
                <busca-input
                  v-model="buscaDigitada"
                  :placeholder="visualizacaoOpcoes.placeholderBusca || 'Buscar por palavra chave'"
                  @input="buscar(false)"
                  @clean="cleanInput"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="filtroDePara">
              <de-para
                v-model="datas"
                :blocked-end="false"
                data-inicial-label=""
                data-final-label=""
                :data-inicial-placeholder="filtroDeHolder"
                :data-final-placeholder="filtroParaHolder"
                @input="filtroData"
              />
            </b-col>
            <b-col
              v-if="temFiltroExtra"
              :cols="getTamanhoFiltroExtra()"
            >
              <slot name="filtro-extra" />
            </b-col>
            <b-col
              v-if="temFiltroRapido"
              :cols="temFiltroExtra ? '3' : '4'"
            >

              <filtro-rapido
                v-if="filtrosRapidos && filtrosRapidos.length > 0"
                :filtros="filtrosRapidos"
                :filtro-atual="filtroAtual"
                @selecionado="aplicarFiltroRapido($event)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="botoesOpcoes"
          cols="auto"
        >
          <botoes-area
            ref="botoesArea"
            :opcoes="botoesOpcoes"
            @removido="buscar()"
          >
            <slot
              slot="botoes-adicionais"
              name="botoes-adicionais"
            />
          </botoes-area>
        </b-col>
        <b-col
          v-else-if="showBotaoCustom"
          cols="auto"
        >
          <slot
            name="show-botao-custom"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <vue-good-table
            ref="gridVisualizacao"
            mode="remote"
            row-style-class="clickable"
            class="lista-grid"
            theme="view"
            :columns="gridInfo.colunas.filter(coluna => coluna.visible == undefined || coluna.visible)"
            :rows="gridInfo.dados"
            :fixed-header="false"
            :total-rows="gridInfo.totalLinhas"
            :is-loading="isCarregando"
            :select-options="{
              enabled: !visualizacaoOpcoes.disabledCheckbox,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true,
              selectAllByGroup: true,
            }"
            :pagination-options="{
              enabled: true,
              perPage: enviarServidor.perPage
            }"
            @on-selected-rows-change="onLinhaSelecionada"
            @on-page-change="onPaginaAlterada"
            @on-sort-change="onOrdenacaoAlterada"
          >
            <template
              slot="table-column"
              slot-scope="props"
            >
              <slot
                v-if="props.column.thType === 'custom-header'"
                name="custom-header"
                :props="props"
              />
            </template>

            <template
              slot="table-row"
              slot-scope="props"
            >
              <slot
                v-if="props.column.field === 'acoes'"
                name="acoes"
                :props="props"
              />
              <coluna-acoes
                v-if="mostrarAcoesPadrao && props.column.field === 'acoes'"
                :info="props"
                :visible-remover="botoesOpcoes && botoesOpcoes.remover.visible"
                :visible-editar="botoesOpcoes && botoesOpcoes.editar.visible"
                @editar="editar"
                @remover="remover"
              />

              <slot
                v-if="props.column.field === 'acoes'"
                name="acoesFinal"
                :props="props"
              />
              <span
                v-if="props.column.type === 'custom'"
              >
                <slot
                  name="custom"
                  :props="props"
                />
              </span>
              <coluna-personalizada
                v-else-if="props.column.type || props.column.editable"
                :info="props"
              />
              <span
                v-else
              >
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- no records -->
            <div slot="emptystate">
              <p style="text-align:center; margin:0">
                Nenhum registro encontrado.
              </p>
            </div>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <paginacao
                :value="props"
                :per-page="enviarServidor.perPage"
              />
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-col>
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </b-row>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.editable-column {
  cursor: pointer;
  text-decoration: underline;
  cursor: pointer;
}

table.vgt-table {
  border-collapse: separate !important;
  border-spacing: 0;
  border-top: 0 !important;
}

table.vgt-table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

table.vgt-table thead tr:first-child th {
  border-top: 1px solid #dcdfe6 !important
}

table.vgt-table th, .vgt-table td {
  border-width: .5px !important;
}

.lista-grid {
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(122, 122, 122, 0.4);
      border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 15px $primary;
  }

  .nowrap {
    white-space: nowrap !important;
  }
}
</style>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'GridVisualizacao',
  components: {
    VueGoodTable,
    ColunaAcoes: () => import('./components/coluna-acoes/ColunaAcoes.vue'),
    ColunaPersonalizada: () => import('./components/coluna-personalizada/ColunaPersonalizada.vue'),
    BotoesArea: () => import('./components/botoes-area/BotoesArea.vue'),
    BuscaInput: () => import('@pilar/components/busca-input/BuscaInput.vue'),
    FiltroRapido: () => import('./components/filtro-rapido/FiltroRapido.vue'),
    Paginacao: () => import('./components/paginacao/Paginacao.vue'),
    DePara: () => import('@pilar/plugins/de-para/DePara.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    visualizacaoOpcoes: {
      type: Object,
      required: true,
    },
    botoesOpcoes: {
      type: Object,
      default: null,
    },
    filtros: {
      type: Array,
      default: () => [],
    },
    filtrosRapidos: {
      type: Array,
      default: () => [],
    },
    temFiltroExtra: {
      type: Boolean,
      default: false,
    },
    temFiltroRapido: {
      type: Boolean,
      default: true,
    },
    esconderAcoes: {
      type: Boolean,
      default: false,
    },
    nomeBotaoAcao: {
      type: String,
      default: 'Ação',
    },
    mostrarAcoesPadrao: {
      type: Boolean,
      default: true,
    },
    filtroDePara: {
      type: Boolean,
      default: false,
    },
    filtroDeHolder: {
      type: String,
      default: 'Data inicial',
    },
    filtroParaHolder: {
      type: String,
      default: 'Data final',
    },
    showBotaoCustom: {
      type: Boolean,
      default: false,
    },
    dimensionarLista: {
      type: Boolean,
      default: true,
    },
    filtroExtraCustom: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isCarregando: false,
      buscaDigitada: '',
      enviarServidor: {
        page: 1,
        perPage: 50,
        ordenacao: [],
        filtros: [],
        linhaSelecionada: -1,
      },
      filtroImutavel: [],
      gridInfo: {
        colunas: [],
        dados: [],
        totalLinhas: 0,
      },
      filtroAtual: null,
      datas: {
        dataInicio: null,
        dataFim: null,
      },
      isLoading: false,
    }
  },
  computed: {
    colunasBusca: {
      get() {
        if (this.filtroDePara) {
          return '4'
        }
        if (this.temFiltroExtra && this.temFiltroRapido && this.filtroExtraCustom !== null) {
          return 9 - this.filtroExtraCustom
        }
        if (this.temFiltroExtra) {
          return '6'
        }
        if (this.temFiltroRapido) {
          return '8'
        }
        return '12'
      },
    },
  },
  created() {
    this.gridInfo.colunas = this.esconderAcoes
      ? [...this.visualizacaoOpcoes.colunas]
      : [
        ...this.visualizacaoOpcoes.colunas,
        {
          label: this.nomeBotaoAcao,
          field: 'acoes',
          thClass: 'th-acao',
          tdClass: this.visualizacaoOpcoes.acaoClass,
          sortable: false,
          width: '100px',
        }]
    this.filtroImutavel = [...this.filtros]
    this.enviarServidor.filtros = this.filtros
    if (!this.filtroViaQuery()) {
      this.buscar(true)
    }
  },
  mounted() {
    this.adjustTableHeight()
    window.addEventListener('resize', this.adjustTableHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTableHeight)
  },
  methods: {
    refresh() {
      this.buscar()
    },
    buscar(habilitarLoading = false) {
      if (!this.isCarregando && !this.isLoading) {
        this.montarGrid(this.enviarServidor, habilitarLoading)
      }
    },
    cleanInput() {
      this.buscaDigitada = ''
      this.buscar(this.enviarServidor)
    },
    alterarRota(filtro) {
      let alterar = false
      const queries = JSON.parse(JSON.stringify(this.$route.query || {}))
      if (queries.busca !== this.buscaDigitada) {
        queries.busca = this.buscaDigitada
        alterar = true
      }
      if (filtro && filtro.id && queries.filtroRapido !== filtro.id) {
        // eslint-disable-next-line prefer-destructuring
        queries.filtroRapido = filtro.id
        alterar = true
      } else if (filtro === null && queries.filtroRapido) {
        queries.filtroRapido = ''
        alterar = true
      }
      if (filtro && (filtro.dataInicio || filtro.dataInicio === '')) {
        queries.dataInicio = filtro.dataInicio
        alterar = true
      }
      if (filtro && (filtro.dataFim || filtro.dataFim === '')) {
        queries.dataFim = filtro.dataFim
        alterar = true
      }
      if (alterar) {
        this.$router.replace({ query: queries })
      }
    },
    setValorFiltro() {
      if (this.enviarServidor.filtros && this.enviarServidor.filtros.length > 0) {
        this.enviarServidor.filtros[0].valor = this.buscaDigitada
        this.alterarRota()
      }
    },
    aplicarFiltro(busca, filtroRapido) {
      this.buscaDigitada = busca
      if (filtroRapido) {
        this.filtroAtual = filtroRapido
      }
    },
    aplicarFiltroRapido(filtro, filtroInterno = true) {
      const temFiltroData = this.enviarServidor.filtros.some(some => some.colunas.includes('DataDe') || some.colunas.includes('DataPara'))
      this.enviarServidor.filtros = this.temFiltroExtra || temFiltroData ? this.enviarServidor.filtros : [...this.filtroImutavel]
      if (this.temFiltroExtra || temFiltroData || filtro === null) {
        const index = filtroInterno ? this.enviarServidor.filtros.findIndex(where => where.filtroInterno)
          : this.enviarServidor.filtros.findIndex(where => where.colunas.length === 1 && where.colunas.some(any => filtro === null || any === (filtro.colunas[0])))
        if (index >= 0) {
          this.enviarServidor.filtros.splice(index, 1)
        }
      }
      if (filtro && filtro !== null) {
        // eslint-disable-next-line no-param-reassign
        filtro.filtroInterno = filtroInterno
        this.enviarServidor.filtros.push(filtro)
      }
      this.alterarRota(filtro)
      this.montarGrid(this.enviarServidor)
    },
    montarGrid(enviarServidor, habilitarLoading = false) {
      if (habilitarLoading) {
        this.isLoading = true
      } else {
        this.isCarregando = true
      }

      this.setValorFiltro()
      this.visualizacaoOpcoes
        .evento(enviarServidor.page, enviarServidor.perPage,
          {
            filtroBy: enviarServidor.filtros,
            ordemBy: enviarServidor.ordenacao,
          })
        .then(payload => {
          this.$set(this.gridInfo, 'dados', payload.data.valores)
          this.$set(this.gridInfo, 'totalLinhas', payload.data.total)
          this.$emit('carregado', this.gridInfo.dados)
          this.$emit('suporte', payload.data.suporte)
        })
        .catch(err => {
          // this.$erroResponse(err.response)
          throw err
        })
        .finally(() => {
          this.isLoading = false
          this.isCarregando = false
        })
    },
    onLinhaSelecionada(param) {
      this.$refs.botoesArea.linhas = param.selectedRows
    },
    onOrdenacaoAlterada(param) {
      const colOrdenada = param[0]
      this.enviarServidor.ordenacao = [{
        tipo: colOrdenada.type,
        coluna: colOrdenada.field,
      }]
      this.montarGrid(this.enviarServidor, true)
    },
    onPaginaAlterada(param) {
      this.enviarServidor.page = param.currentPage
      this.montarGrid(this.enviarServidor)
    },
    editar(linha) {
      this.botoesOpcoes.editar.evento(linha)
    },
    remover(linha) {
      const contemVarios = ((Array.isArray(linha) && linha.length > 1))
      this.$confirmar('Remover?', `Tem certeza que deseja remover ${contemVarios ? 'os registros' : 'o registro'}?`, 'warning')
        .then(result => {
          if (result.value) {
            this.botoesOpcoes.remover.evento(linha)
              .then(() => {
                this.$emit('removido')
                this.$confirmar('Removido!', '', 'success')
                this.buscar()
              })
              .catch(err => {
                if (err.message) {
                  this.$erro(err.message)
                } else {
                  this.$notificar('Não foi possível remover as informações', 'danger')
                }
                throw err
              })
          }
        })
    },
    filtroData(evt) {
      this.datas = evt
      this.aplicarFiltroData()
      this.alterarRota(evt)
    },
    aplicarFiltroData() {
      if (this.datas.dataInicio || this.datas.dataInicio === '') {
        if (this.enviarServidor.filtros.some(some => some.colunas.includes('DataDe'))) {
          const index = this.enviarServidor.filtros.findIndex(where => where.colunas.includes('DataDe'))
          this.enviarServidor.filtros[index].valor = this.datas.dataInicio
        } else {
          this.enviarServidor.filtros.push({ colunas: ['DataDe'], valor: this.datas.dataInicio })
        }
      }
      if (this.datas.dataFim || this.datas.dataFim === '') {
        if (this.enviarServidor.filtros.some(some => some.colunas.includes('DataPara'))) {
          const index = this.enviarServidor.filtros.findIndex(where => where.colunas.includes('DataPara'))
          this.enviarServidor.filtros[index].valor = this.datas.dataFim
        } else {
          this.enviarServidor.filtros.push({ colunas: ['DataPara'], valor: this.datas.dataFim })
        }
      }
      this.buscar()
    },
    filtroViaQuery() {
      let success = false
      if (this.filtroRapidoViaQuery()) {
        success = true
      }
      if (this.filtroDataViaQuery()) {
        success = true
      }
      return success
    },
    filtroDataViaQuery() {
      const query = JSON.parse(JSON.stringify(this.$route.query || {}))
      const temData = query.dataInicio || query.dataFim
      if (query.dataInicio) {
        this.datas.dataInicio = query.dataInicio
      }
      if (query.dataFim) {
        this.datas.dataFim = query.dataFim
      }
      if (temData) {
        this.aplicarFiltroData()
        return true
      }
      return false
    },
    filtroRapidoViaQuery() {
      const query = this.$route.query.filtroRapido
      if (query && this.filtrosRapidos) {
        const item = this.filtrosRapidos.find(find => find.id === query)
        if (item) {
          this.aplicarFiltroRapido(item)
          return true
        }
      }
      return false
    },
    getTamanhoFiltroExtra() {
      if (this.filtroExtraCustom !== null) {
        return this.filtroExtraCustom
      }
      if (this.filtroDePara && this.temFiltroRapido) {
        return '2'
      }
      if (this.filtroDePara || this.temFiltroRapido) {
        return '3'
      }
      return '6'
    },
    adjustTableHeight() {
      const windowHeight = window.innerHeight
      const tableContainer = this.$refs.gridVisualizacao.$el.parentElement
      const vgtResponsive = tableContainer.querySelector('.vgt-responsive')

      const tableContainerTop = this.$el.parentElement.getBoundingClientRect().top
      let tableHeight = 0

      if (tableContainer.getBoundingClientRect().top < 230) {
        tableHeight = windowHeight - tableContainerTop - tableContainerTop * 1.23
      } else {
        tableHeight = windowHeight - tableContainerTop - tableContainerTop * 1.46
      }
      vgtResponsive.style.maxHeight = `${tableHeight}px`
    },
  },
}
</script>
